<template>
  <div>
    <BaseNav></BaseNav>

    <!-- Page content -->
    <div class="page-content">
      <!-- Main content -->
      <div class="content-wrapper">
        <!-- Content area -->
        <div class="content d-flex justify-content-center align-items-center">
          <!-- Registration form -->
          <form
            class="flex-fill "
            ref="registerForm"
            @submit.prevent="submitRegister"
          >
            <div class="row">
              <div class="col-lg-6 offset-lg-3">
                <div class=" mb-0 register-form rounded">
                  <div class="">
                    <div class="text-center mb-3">
                      <h5 class="mb-0 bold-title ">Join us!</h5>
                      <div class="sub-title-hint">Hundred thousands of students <br> achieve goals with US!</div>
                      <!-- <span class="d-block text-muted"
                        >Hundred thousands of students achieve goals with US!</span
                      > -->
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <div
                          class="form-group form-group-feedback form-group-feedback-right"
                        >
                          <input
                            v-model="registerForm.first_name"
                            required
                            name="first_name"
                            type="text"
                            class="form-control"
                            placeholder="First name"
                          />
                          <div class="form-control-feedback">
                            <i class="icon-user-check text-muted"></i>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div
                          class="form-group form-group-feedback form-group-feedback-right"
                        >
                          <input
                            v-model="registerForm.last_name"
                            name="last_name"
                            required
                            type="text"
                            class="form-control"
                            placeholder="Second name"
                          />
                          <div class="form-control-feedback">
                            <i class="icon-user-check text-muted"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="form-group form-group-feedback form-group-feedback-right"
                    >
                      <input
                        v-model="registerForm.email"
                        name="email"
                        required
                        type="email"
                        class="form-control"
                        placeholder="Email Address"
                      />
                      <div class="form-control-feedback">
                        <i class="icon-mention text-muted"></i>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-6">
                        <div
                          class="form-group form-group-feedback form-group-feedback-right"
                        >
                          <v-select 
                              label="name" 
                              v-model="registerForm.country" 
                              placeholder="Start typing to search" 
                              :options="countryNames"
                              :reduce="country => country" 
                              >
                          </v-select>

                         
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div
                          class="form-group form-group-feedback form-group-feedback-right"
                        >
                          <input
                            v-model="registerForm.phone"
                            name="phone"
                            required
                            type="tel"
                            class="form-control"
                            placeholder="Your Phone"
                          />
                          <div class="form-control-feedback">
                            <i class="icon-phone text-muted"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <div
                          class="form-group form-group-feedback form-group-feedback-right"
                        >
                          <input
                            v-model="registerForm.password"
                            name="password"
                            type="password"
                            required
                            class="form-control"
                            placeholder="Create password"
                          />
                          <div class="form-control-feedback">
                            <i class="icon-user-lock text-muted"></i>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div
                          class="form-group form-group-feedback form-group-feedback-right"
                        >
                          <input
                            v-model="registerForm.password_confirmation"
                            name="password_confirmation"
                            required
                            type="password"
                            class="form-control"
                            placeholder="Repeat password"
                          />
                          <div class="form-control-feedback">
                            <i class="icon-user-lock text-muted"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                    <base-captcha></base-captcha>

                    <div class="form-group">
                      <!-- <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-input-styled"
                            checked
                            data-fouc
                          />
                          Send me <a href="#">test account settings</a>
                        </label>
                      </div> 
                      
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            type="checkbox"
                            class="form-input-styled"
                            checked
                            data-fouc
                          />
                          Subscribe to monthly newsletter
                        </label>
                      </div> -->

                    </div>

                    <button
                      :disabled="registerForm.busy"
                      type="submit"
                      class="btn btn--custom w-50 mb-2 btn-labeled btn-labeled-right"
                    >
                       {{registerForm.busy ? 'Loading..': 'Create Account'}}
                    </button>

                    <div class="form-group py-2 text-center text-muted content-divider">
                      <span class="px-2">Already have an account?</span>
                    </div>

                    <div class="form-group d-flex align-items-center justify-content-center w-100 ">
                      <router-link
                        :to="{ name: 'Login' }"
                        
                        class="btn btn--primary w-50 py-2 btn-block"
                        >Sign in</router-link
                      >
                    </div>
                    <div class="form-group d-flex align-items-center justify-content-center w-100 ">
                      <span class="form-text text-center w-50 text-muted"
                        >By continuing, you're confirming that you've read our
                        <a href="#">Terms &amp; Conditions</a> and
                        <a href="#">Cookie Policy</a></span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <!-- /registration form -->
        </div>
        <!-- /content area -->
      </div>
      <!-- /main content -->
    </div>
    <!-- /page content -->
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {countries} from "countries-list";
export default {
  data() {
    return {
      registerForm: new this.$Form({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        country: "",
        password: "",
        token: "",
      }),
    };
  },

  computed: {
    ...mapState("auth", ["loginForm"]),
    countryNames() {
      return Object.keys(countries).map((country) => countries[country].name)
    }
  },


  methods: {
    ...mapActions("auth", ["doRegister", "doLogin"]),
    submitRegister() {
      let token
      if (process.env === "production") {
        token = window.grecaptcha.getResponse();
      } else {
        token = "fasdflk";
      }
      if (!token || token == "") {
        this.$notify({
              title: "Error",
              text: "Please verify you are not a robot",
              style: "danger",
            });
      } else {
        this.doRegister.token = token
        this.doRegister(this.registerForm)
          .then((res) => {
            console.log("res.data", res.data);
            this.$notify({
              title: "Success",
              text: "Registration successiful!",
            });
            // login
            this.doLogin(this.registerForm)
              .then((res) => {
                console.log(res.data.Data);
                document.location.href = `/${process.env.VUE_APP_INSTALL_DIR}/home/`
              })
              .catch((err) => {
                this.$notify({
                  title: "Error",
                  text: err.response.data ? err.response.data.Message : "Error",
                  style: "danger",
                });
              });
          })
          .catch((err) => {
            this.$notify({
              title: "Error",
              text: err.response.data ? err.response.data.Message : "Error",
              style: "danger",
            });
          });
        }
    },
  },
};
</script>
